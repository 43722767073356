import styled, { keyframes } from 'styled-components';

export const buzz = () => keyframes`
    50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  `;

export const buzzOut = () => keyframes`
    10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    -moz-transform:    translateX(3px) rotate(2deg);
    -ms-transform:     translateX(3px) rotate(2deg);
    -o-transform:      translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    -moz-transform:    translateX(-3px) rotate(-2deg);
    -ms-transform:     translateX(-3px) rotate(-2deg);
    -o-transform:      translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    -moz-transform:    translateX(3px) rotate(2deg);
    -ms-transform:     translateX(3px) rotate(2deg);
    -o-transform:      translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    -moz-transform:    translateX(-3px) rotate(-2deg);
    -ms-transform:     translateX(-3px) rotate(-2deg);
    -o-transform:      translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    -moz-transform:    translateX(2px) rotate(1deg);
    -ms-transform:     translateX(2px) rotate(1deg);
    -o-transform:      translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    -moz-transform:    translateX(2px) rotate(1deg);
    -ms-transform:     translateX(2px) rotate(1deg);
    -o-transform:      translateX(2px) rotate(1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    -moz-transform:    translateX(2px) rotate(1deg);
    -ms-transform:     translateX(2px) rotate(1deg);
    -o-transform:      translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    -moz-transform:    translateX(-2px) rotate(-1deg);
    -ms-transform:     translateX(-2px) rotate(-1deg);
    -o-transform:      translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    -moz-transform:    translateX(1px) rotate(0);
    -ms-transform:     translateX(1px) rotate(0);
    -o-transform:      translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    -moz-transform:    translateX(-1px) rotate(0);
    -ms-transform:     translateX(-1px) rotate(0);
    -o-transform:      translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
  `;

export const float = keyframes`
  	0% {
		transform: translatey(0px);
	}
	100% {
		transform: translatey(-10px);
	}
  `;

export const fadeIn = () => keyframes`
  	0% {
		opacity: 0;
	}
  	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
  `;

export const BuzzOut = styled.span`
  -webkit-animation: ${buzzOut()} 0.2s 1;
  -moz-animation: ${buzzOut()} 0.2s 1;
  -o-animation: ${buzzOut()} 0.2s 1;
  animation: ${buzzOut()} 0.2s 1;
  width: auto;
  align-self: center;
  height: auto;
`;

export const typing = () => keyframes`
  from { width: 0 }
  to { width: 100% }
`;

export const Typing = styled.span`
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end);
`;
