import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Feature } from 'types/dungeon';
import { FlexWrapper, ResultTitle, ReasonText } from './styled';
import { BuzzOut } from 'utils/animations';

type Props = {
  reason: Feature;
  updateFn: () => { type: string };
};

const Reason: React.FC<Props> = ({ reason, updateFn }) => {
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    setAnimating(!animating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason]);

  return (
    <FlexWrapper>
      <BuzzOut key={`${animating}`}>
        <ResultTitle onClick={updateFn}>
          <span>
            <FormattedMessage id='dungeon.reason.title' />
          </span>
        </ResultTitle>
      </BuzzOut>
      <ReasonText>
        <FormattedMessage id={reason.message} values={reason.values} />
      </ReasonText>
    </FlexWrapper>
  );
};

export default Reason;
