import styled from 'styled-components';
import { mediaQuery } from 'utils/mediaQueries';

type FlexWrapperProps = {
  row: number;
};

export const FlexWrapper = styled.div<FlexWrapperProps>`
  grid-column: 2;
  grid-row: ${({ row }) => row};
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px ${({ theme }) => theme.text.primary} solid;
  border-top: 1px ${({ theme }) => theme.text.primary} solid;

  > div:nth-child(even) {
    border-left: 1px ${({ theme }) => theme.text.primary} solid;
    border-right: 1px ${({ theme }) => theme.text.primary} solid;
  }

  ${mediaQuery.tablet} {
    grid-column: 1;
  }

  ${mediaQuery.phone} {
    flex-direction: column;
    height: 100%;
    > div:nth-child(even) {
      border-left: none;
      border-right: none;
      border-top: 1px ${({ theme }) => theme.text.primary} solid;
      border-bottom: 1px ${({ theme }) => theme.text.primary} solid;
    }
  }
`;
