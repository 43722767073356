import sample from 'lodash/fp/sample';
import { Feature, FeatureTable } from '../../types/dungeon';

export const rollFeature = (table: FeatureTable, id?: string): Feature => {
  const feature = sample(table.results.filter((f) => f.id !== id))!;

  const values = feature.generateValues ? feature.generateValues()! : {};

  return {
    ...feature,
    values,
  };
};
