import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { FlexWrapper, RoomText, RoomTitle } from './styled';
import { BuzzOut } from 'utils/animations';
import { Room } from 'types/dungeon';

type Props = {
  index: number;
  room: Room;
  updateFn: (id: string) => { type: string; payload: { id: string } };
};

const RoomBox: React.FC<Props> = ({ index, room, updateFn }) => {
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    setAnimating(!animating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  return (
    <FlexWrapper>
      <BuzzOut key={`${animating}`}>
        <RoomTitle onClick={() => updateFn(room.id)}>
          <span>
            <FormattedMessage
              id={'room.title'}
              values={{ roomNumber: index }}
            />
          </span>
        </RoomTitle>
      </BuzzOut>
      <RoomText>
        {room.descriptions.map((description, i) => {
          if (i !== room.descriptions.length - 1) {
            return (
              <span key={i}>
                <FormattedHTMLMessage
                  id={description.message}
                  values={description.values}
                />{' '}
              </span>
            );
          }
          return (
            <span key={i}>
              <FormattedHTMLMessage
                id={description.message}
                values={description.values}
              />
            </span>
          );
        })}
      </RoomText>
    </FlexWrapper>
  );
};

export default RoomBox;
