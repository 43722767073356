import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: visible;
    color: ${({ theme }) => theme.text.primary};
    background: ${({ theme }) => theme.background};
    margin: 0;
    font-size: 1em;
    font-family: 'calling-code', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    ::-webkit-scrollbar {
      width: 0.5em;
      background-color: ${({ theme }) => theme.faded};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.text.secondary};
      outline: none;
    }
  }
`;
