import styled from 'styled-components';
import { mediaQuery } from 'utils/mediaQueries';

export const FlexWrapper = styled.div`
  grid-column: 2;
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  justify-items: center;
  align-self: stretch;

  ${mediaQuery.tablet} {
    grid-template-columns: 1fr;
  }

  * > a {
    color: ${({ theme }) => theme.text.primary};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.highlight.primary};
    :hover {
      background-color: ${({ theme }) => theme.highlight.primary};
    }
    :active {
      background-color: ${({ theme }) => theme.highlight.primary};
    }
  }
`;
