import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 4;
  grid-column: 1 / span 3;
  justify-content: center;
  align-self: end;
`;
