import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FlexWrapper } from './styled';
import DungeonName from '../components/DungeonName';
import FeatureRow from '../components/FeatureRow';
import Reason from '../components/Reason';
import RoomRow from '../components/RoomRow';
import {
  selectRooms,
  selectFeature,
  selectDungeonName,
} from 'ducks/dungeon/selectors';

const Dungeon: React.FC = () => {
  const dispatch = useDispatch();
  const name = useSelector(selectDungeonName);
  const rooms = useSelector(selectRooms);
  const reason = useSelector(selectFeature('reason'));
  const status = useSelector(selectFeature('status'));
  const danger = useSelector(selectFeature('danger'));
  const inhabitants = useSelector(selectFeature('inhabitants'));
  const entrance = useSelector(selectFeature('entrance'));
  const guard = useSelector(selectFeature('guard'));
  const distinctiveFeature = useSelector(selectFeature('distinctiveFeature'));

  useEffect(() => {
    dispatch({ type: 'UPDATE_DUNGEON' });
  }, [dispatch]);

  return (
    <>
      <FlexWrapper>
        <DungeonName
          name={name}
          updateFn={() => dispatch({ type: 'UPDATE_DUNGEON_NAME' })}
        />
        <FeatureRow
          row={2}
          features={[
            {
              id: 'status',
              feature: status,
            },
            {
              id: 'danger',
              feature: danger,
            },
            {
              id: 'inhabitants',
              feature: inhabitants,
            },
          ]}
          updateFn={(id: string) => () =>
            dispatch({ type: 'UPDATE_DUNGEON_FEATURE', payload: { id } })}
        />
        <Reason
          reason={reason}
          updateFn={() =>
            dispatch({
              type: 'UPDATE_DUNGEON_FEATURE',
              payload: { id: 'reason' },
            })
          }
        />
        <FeatureRow
          row={4}
          features={[
            {
              id: 'entrance',
              feature: entrance,
            },
            {
              id: 'guard',
              feature: guard,
            },
            {
              id: 'distinctiveFeature',
              feature: distinctiveFeature,
            },
          ]}
          updateFn={(id: string) => () =>
            dispatch({ type: 'UPDATE_DUNGEON_FEATURE', payload: { id } })}
        />
        <RoomRow
          row={5}
          rooms={rooms}
          updateFn={(id: string) =>
            dispatch({ type: 'UPDATE_ROOM_DESCRIPTIONS', payload: { id } })
          }
        />
      </FlexWrapper>
    </>
  );
};

export default Dungeon;
