import React from 'react';
import { FlexWrapper } from './styled';
import FeatureBox from '../FeatureBox';
import { Feature } from 'types/dungeon';

type Props = {
  row: number;
  features: FeatureProp[];
  updateFn: (id: string) => () => { type: string; payload: { id: string } };
};

type FeatureProp = {
  id: string;
  feature: Feature;
};

const FeatureRow: React.FC<Props> = ({ row, features, updateFn }) => {
  return (
    <FlexWrapper row={row}>
      {features.map((f) => (
        <FeatureBox
          key={f.id}
          feature={f.feature}
          title={f.id}
          updateFn={updateFn(f.id)}
        />
      ))}
    </FlexWrapper>
  );
};

export default FeatureRow;
