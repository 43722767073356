import React from 'react';
import DNGNGENlogo from 'assets/DNGNGEN.svg';
import { StyledAngledBlock, FlexWrapper, Tendrils, Tendril, DNGNGENLogo } from './styled';

export const DNGNGEN: React.FC = () => {
  return (
    <FlexWrapper>
      <DNGNGENLogo src={DNGNGENlogo} alt='the DNGNGEN logo' />
      <StyledAngledBlock />
      <Tendrils>
        <Tendril />
        <Tendril />
        <Tendril />
        <Tendril />
      </Tendrils>
    </FlexWrapper>
  );
};
