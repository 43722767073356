import React from 'react';
import { FlexWrapper } from './styled';
import RoomBox from '../RoomBox';
import { Room } from 'types/dungeon';

type Props = {
  rooms: Room[];
  row: number;
  updateFn: (id: string) => { type: string; payload: { id: string } };
};

const RoomRow: React.FC<Props> = ({ row, rooms, updateFn }) => {
  return (
    <FlexWrapper row={row}>
      {rooms.map((room: Room, index: number) => (
        <RoomBox
          key={room.id}
          room={room}
          index={index + 1}
          updateFn={updateFn}
        />
      ))}
    </FlexWrapper>
  );
};

export default RoomRow;
