import React from 'react';
import { Provider } from 'react-intl-redux';
import { store } from 'ducks/store';
import MainPage from 'views/Main/containers';

export const App = () => {
  return (
    <Provider store={store}>
      <MainPage />
    </Provider>
  );
};
