import { mediaQuery } from 'utils/mediaQueries';
import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 100%) 760px minmax(0px, 100%);
  grid-template-rows:
    120px minmax(min-content, max-content) minmax(min-content, max-content)
    1fr;
  height: 100vh;
  width: 100%;

  ${mediaQuery.laptop} {
    grid-template-rows:
      120px minmax(min-content, max-content) minmax(min-content, max-content)
      1fr;
  }

  ${mediaQuery.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows:
      240px minmax(min-content, max-content) minmax(min-content, max-content)
      1fr;
  }

  ${mediaQuery.phone} {
    grid-template-columns: 1fr;
    grid-template-rows:
      240px minmax(min-content, max-content) minmax(min-content, max-content)
      1fr;
  }
`;
