import React from 'react';
import kartellLogo from 'assets/KRTL_logo.svg';
import ockultLogo from 'assets/Ockult_logo.svg';
import { FlexWrapper, KartellLogo, OckultLogo } from './styled';

export const OckultKartell: React.FC = () => {
  return (
    <FlexWrapper>
      <KartellLogo src={kartellLogo} alt='the Stockholm Kartell logo' />
      <OckultLogo src={ockultLogo} alt='the Ockult Örtmästare logo' />
    </FlexWrapper>
  );
};
