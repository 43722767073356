import { mediaQuery } from 'utils/mediaQueries';
import styled from 'styled-components';

export const FlexWrapper = styled.div`
  grid-column: 4;
  grid-row: 1;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-column-gap: 20px;

  ${mediaQuery.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1 / span 3;
    grid-row: 1;
    align-items: end;
  }

  ${mediaQuery.phone} {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1;
    grid-row: 1;
    align-items: center;
  }
`;

export const KartellLogo = styled.img`
  z-index: 3;
  grid-column: 2;
  grid-row: 1;
  width: 100px;
  justify-self: start;

  ${mediaQuery.tablet} {
    grid-column: 3;
    grid-row: 1;
    justify-self: center;
  }

  ${mediaQuery.phone} {
    visibility: hidden;
    grid-column: 2;
    grid-row: 1;
    justify-self: center;
  }
`;

export const OckultLogo = styled.img`
  z-index: 3;
  grid-column: 1;
  grid-row: 1;
  width: 140px;
  justify-self: center;

  ${mediaQuery.tablet} {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
  }

  ${mediaQuery.phone} {
    visibility: hidden;
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
  }
`;
