import sample from 'lodash/fp/sample';
import {
  DungeonNameTable,
  DungeonName,
  DungeonNameTableResult,
  Values,
} from '../../types/dungeon';

type Format = {
  id: string;
  likelihood: number;
  roll: (tables: {
    [table: string]: DungeonNameTable;
  }) => { [part: string]: DungeonNameTableResult };
};

const formatDeclarations: Format[] = [
  {
    id: 'dungeon.name.format.something-noun',
    likelihood: 12,
    roll: (tables) => ({
      something: sample(tables.something.results)!,
      noun: sample(tables.noun.results)!,
    }),
  },
  {
    id: 'dungeon.name.format.someones-noun',
    likelihood: 3,
    roll: (tables) => ({
      someones: sample(tables.someones.results)!,
      noun: sample(tables.noun.results)!,
    }),
  },
  {
    id: 'dungeon.name.format.the-noun-of-something',
    likelihood: 6,
    roll: (tables) => ({
      noun: sample(tables.noun.results)!,
      something: sample(tables.ofSomething.results)!,
    }),
  },
  {
    id: 'dungeon.name.format.the-something-noun-of-someone',
    likelihood: 3,
    roll: (tables) => ({
      something: sample(tables.something.results)!,
      noun: sample(tables.noun.results)!,
      someone: sample(tables.someone.results)!,
    }),
  },
  {
    id: 'dungeon.name.format.the-something-noun-of-someone-the-title',
    likelihood: 1,
    roll: (tables) => ({
      something: sample(tables.something.results)!,
      noun: sample(tables.noun.results)!,
      someone: sample(tables.someone.results)!,
      title: sample(tables.title.results)!,
    }),
  },
];

const randomFormat = (formats: Format[]) => {
  const weightedFormats = formats.reduce((result: Format[], f) => {
    return [...result, ...Array.apply(null, Array(f.likelihood)).map((_) => f)];
  }, []);
  return sample(weightedFormats);
};

export const rollDungeonName = (tables: {
  [table: string]: DungeonNameTable;
}): DungeonName => {
  const format = randomFormat(formatDeclarations)!;
  const messages = format.roll(tables);
  const values: Values | {} = Object.values(messages).reduce(
    (result: Values, message: DungeonNameTableResult) => {
      if (message.generateValues) {
        return { ...result, ...message.generateValues() };
      }
      return result;
    },
    {}
  );

  return {
    format: format.id,
    messages: Object.entries(messages).reduce(
      (result, [key, message]: [string, DungeonNameTableResult]) => {
        return { ...result, [key]: message.id };
      },
      {}
    ),
    values,
  };
};
