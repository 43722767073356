import { FeatureTable, FeatureTableResult } from 'types/dungeon';
import random from 'lodash/fp/random';
import sample from 'lodash/fp/sample';

const feature = (
  id: string,
  generateValues?: () => { [value: string]: number | string }
): FeatureTableResult => {
  const message = `dungeon.feature.${id}`;
  return {
    id,
    message,
    generateValues,
  };
};

export const Reasons: FeatureTable = {
  id: 'featureTableReasons',
  results: [
    feature('reason.dungeon-death'),
    feature('reason.someone-you-know'),
    feature('reason.occult-treasures'),
    feature('reason.lost-bet'),
    feature('reason.bronze-golem', () => ({
      ability: sample(['loyal', 'curses', 'he'])!,
    })),
    feature('reason.basilisk-effigy', () => ({
      basilisk: sample(['he', 'she'])!,
    })),
    feature('reason.revenge'),
    feature('reason.plague-cure'),
    feature('reason.horrible-beast'),
    feature('reason.seek-shelter'),
    feature('reason.lost'),
    feature('reason.hypnotic-star'),
    feature('reason.portal'),
    feature('reason.black-disk'),
    feature('reason.plague-artifact'),
    feature('reason.cube-violet'),
    feature('reason.cursed-treasure'),
    feature('reason.eyes-of-icon'),
    feature('reason.inquisition'),
    feature('reason.tracked-someone', () => ({
      people: sample([
        'dopplegangers',
        'cult',
        'killer',
        'thieves',
        'heretics',
        'tormentors',
        'traitor',
      ])!,
    })),
    feature('reason.sentenced-to-death', () => ({
      something: sample([
        'urn',
        'mask',
        'orb',
        'figurine',
        'crown',
        'puzzleBox',
      ])!,
    })),
    feature('reason.cursed-by-someone-who-dwells', () => ({
      someone: sample([
        'thornedOne',
        'twinHags',
        'prophet',
        'plagueBearer',
        'child',
        'shimmeringVoid',
      ])!,
    })),
    feature('reason.drunken-boredom'),
    feature('reason.stolen-children', () => ({
      someone: sample(['sibling', 'rulersHeir', 'mastersChild'])!,
      ruler: sample(['king', 'queen'])!,
      child: sample(['son', 'daughter'])!,
    })),
    feature('reason.roadside-inn'),
    feature('reason.you-dont-know'),
    feature('reason.fever-dreams'),
    feature('reason.a-promise-of-something', () => ({
      something: sample([
        'treasure',
        'thrill',
        'silver',
        'knowledge',
        'answers',
      ])!,
    })),
    feature('reason.love'),
  ],
};

export const Status: FeatureTable = {
  id: 'featureTableStatus',
  results: [
    feature('status.still-active'),
    feature('status.still-active'),
    feature('status.still-active'),
    feature('status.still-active'),
    feature('status.place-invaded'),
    feature('status.disaster'),
    feature('status.no-longer-needed'),
    feature('status.invaded'),
    feature('status.misery'),
    feature('status.cursed'),
    feature('status.depraved'),
    feature('status.forgotten'),
  ],
};

export const Entrances: FeatureTable = {
  id: 'featureTableEntrances',
  results: [
    feature('entrance.trapdoor', () => ({
      city: sample(['schleswig', 'galgenbeck'])!,
    })),
    feature('entrance.in-graven-tosk', () => ({
      place: sample(['graves', 'pits', 'mausolea'])!,
    })),
    feature('entrance.cracked-statue-of-someone', () => ({
      someone: sample([
        'prophet',
        'king',
        'ruler',
        'priest',
        'warlord',
        'knight',
      ])!,
    })),
    feature('entrance.cursed-weapon-kills-person', () => ({
      weapon: sample(['dagger', 'rapier', 'sword', 'flail', 'blade'])!,
      person: sample(['noble', 'priest', 'pauper', 'sinner', 'murderer'])!,
    })),
    feature('entrance.obelisk-circle'),
    feature('entrance.skeleton-mouth'),
    feature('entrance.volcano'),
    feature('entrance.rune-sealed-door', () => ({
      emotion: sample([
        'remorse',
        'regret',
        'fear',
        'suffering',
        'panic',
        'pain',
        'torment',
        'envy',
      ])!,
    })),
    feature('entrance.lighthouse', () => ({
      city: sample(['grift', 'allians'])!,
    })),
    feature('entrance.inverted-church'),
    feature('entrance.seven-dead-trees'),
    feature('entrance.winding-stairs', () => ({
      city: sample(['galgenbeck', 'allians', 'schleswig'])!,
    })),
    feature('entrance.sinkhole'),
    feature('entrance.sarcophagus'),
    feature('entrance.cave-opening', () => ({
      place: sample(['bergenChrypt', 'terion'])!,
    })),
    feature('entrance.cracked-ice'),
    feature('entrance.shining-door'),
    feature('entrance.cliffside'),
    feature('entrance.ruin-maze'),
    feature('entrance.asymmetric-structure', () => ({
      region: sample(['wastland', 'tveland', 'kergus'])!,
    })),
    feature('entrance.gate-somewhere', () => ({
      gate: sample(['gate', 'lockedGate'])!,
      somewhere: sample(['crags', 'swamps', 'sarkash'])!,
    })),
    feature('entrance.shack'),
    feature('entrance.gravemound', () => ({
      region: sample(['wastland', 'kergus', 'tveland', 'grift'])!,
    })),
    feature('entrance.large-door'),
    feature('entrance.potion'),
  ],
};

export const Guards: FeatureTable = {
  id: 'featureTableGuards',
  results: [
    feature('guard.no-one'),
    feature('guard.no-one'),
    feature('guard.skeletons', () => ({
      amount: sample(['pair', 'three', 'four'])!,
    })),
    feature('guard.petrified-wyvern'),
    feature('guard.hermit'),
    feature('guard.grotesque'),
    feature('guard.cannibals'),
    feature('guard.people-with-knives', () => ({
      amount: random(3, 5),
      gender: sample(['male', 'female'])!,
    })),
    feature('guard.sick-troll', () => ({
      someone: sample(['ghost', 'demon'])!,
    })),
    feature('guard.dying-goblins', () => ({
      goblins: random(2, 4),
    })),
    feature('guard.graves'),
    feature('guard.huge-bird'),
    feature('guard.naked-man'),
    feature('guard.undead-boar'),
    feature('guard.sarkantha'),
    feature('guard.inquisitors'),
    feature('guard.statues', () => ({
      amount: sample(['rows', 'circle'])!,
      material: sample(['granite', 'basalt', 'glass', 'obsidian'])!,
    })),
    feature('guard.tendrils'),
    feature('guard.ruin-basilisk'),
    feature('guard.wickheads', () => ({
      amount: random(2, 3),
      action: sample(['warm', 'blades', 'meat', 'torturing'])!,
    })),
    feature('guard.scum', () => ({
      amount: random(2, 4),
      action: sample(['arguing', 'drinking', 'fighting', 'dice'])!,
    })),
    feature('guard.zombies-on-a-leash'),
    feature('guard.riddle'),
    feature('guard.darkness'),
    feature('guard.ghost-seeking-retribution'),
  ],
};

export const Dangers: FeatureTable = {
  id: 'featureTableDangers',
  results: [
    feature('danger.flooding', () => ({
      liquid: sample(['water', 'oil', 'tar', 'blood', 'sludge'])!,
    })),
    feature('danger.berserkers'),
    feature('danger.collapsing'),
    feature('danger.distorted-senses'),
    feature('danger.underworld-spores'),
    feature('danger.hunted-cult'),
    feature('danger.dormant-curse'),
    feature('danger.fire', () => ({
      room: sample(['deepestChamber', 'randomRoom'])!,
    })),
    feature('danger.shut-gate'),
    feature('danger.lethal-mechanism'),
    feature('danger.flooding-with-oil'),
    feature('danger.poison-gas'),
    feature('danger.expanding-lifeform'),
    feature('danger.sinking'),
    feature('danger.earthquakes'),
    feature('danger.scorching'),
    feature('danger.maddening-note'),
    feature('danger.closing-in'),
    feature('danger.traps'),
    feature('danger.hallucinogenic-food'),
    feature('danger.tilting-rooms'),
    feature('danger.rifts'),
  ],
};

export const Inhabitants: FeatureTable = {
  id: 'featureTableInhabitants',
  results: [
    feature('inhabitants.a-battle', () => ({
      someone: sample(['suits', 'bandits', 'hyenas', 'druids'])!,
      something: sample(['goblins', 'trolls', 'dolls', 'gnoums', 'spiders'])!,
    })),
    feature('inhabitants.lich', () => ({
      something: sample([
        'skeletalCourt',
        'zombieGuards',
        'ghoulWarriors',
        'spectralCourt',
      ])!,
    })),
    feature('inhabitants.heretic-cult', () => ({
      ruler: sample(['king', 'queen'])!,
      heir: sample(['son', 'daughter'])!,
      someone: sample([
        'elevenyearold',
        'manOfGlass',
        'rulersHeir',
        'charismaticHerbalist',
        'paleCreature',
      ])!,
    })),
    feature('inhabitants.vengeful-cabal'),
    feature('inhabitants.someone-dying-of-something', () => ({
      someone: sample([
        'thieves',
        'inquisitors',
        'monsterHunters',
        'cultists',
      ])!,
      something: sample(['cholera', 'mindRot', 'bloodCough', 'curse'])!,
    })),
    feature('inhabitants.slime'),
    feature('inhabitants.someone-running', () => ({
      someone: sample([
        'wickheads',
        'goblins',
        'trolls',
        'cultists',
        'escapedPrisoners',
      ])!,
    })),
    feature('inhabitants.bark-witch'),
    feature('inhabitants.gremlins'),
    feature('inhabitants.escaped-experiment'),
    feature('inhabitants.disease-spreading-something', () => ({
      something: sample(['beetles', 'birds', 'larvae'])!,
    })),
    feature('inhabitants.dissident-courtiers'),
    feature('inhabitants.lost-creatures', () => ({
      somewhere: sample([
        'lake',
        'earth',
        'blood',
        'anotherPlane',
        'lostContinent',
        'past',
      ])!,
    })),
    feature('inhabitants.undead'),
    feature('inhabitants.insane-prisoners'),
    feature('inhabitants.troll-king'),
    feature('inhabitants.vile-creatures', () => ({
      something: sample(['mud', 'gristle', 'salt', 'ink'])!,
      other: sample(['pus', 'scabs', 'flesh', 'phlegm', 'bone'])!,
    })),
    feature('inhabitants.hexed-someone', () => ({
      someone: sample([
        'cultists',
        'knights',
        'tombRobbers',
        'monsterHunters',
      ])!,
    })),
    feature('inhabitants.the-smelly-what', () => ({
      someone: sample(['unseen', 'silent', 'forgotten'])!,
      smell: sample(['perfume', 'scent', 'stench', 'rot'])!,
      things: sample([
        'drawings',
        'rocks',
        'pebbles',
        'hairs',
        'sculptures',
        'chains',
        'poems',
        'spiderwebs',
      ])!,
    })),
    feature('inhabitants.herbmaster', () => ({
      something: sample(['eggSacs', 'plantServants', 'scrolls', 'hashish'])!,
    })),
    feature('inhabitants.giant-golem', () => ({
      something: sample(['blades', 'iron', 'metal', 'zombies', 'obsidian'])!,
    })),
    feature('inhabitants.deserters'),
    feature('inhabitants.skeletons'),
    feature('inhabitants.cloaked-demon'),
    feature('inhabitants.family', () => ({ amount: random(3, 7) })),
    feature('inhabitants.mutant-thing', () => ({
      beast: sample(['elk', 'boar', 'wolf', 'centipede', 'insect'])!,
    })),
    feature('inhabitants.unending-swarm', () => ({
      things: sample(['spiders', 'ants', 'moths', 'ticks', 'maggots'])!,
    })),
    feature('inhabitants.cannibalistic-undead'),
    feature('inhabitants.hovering-skull'),
  ],
};

export const DistinctiveFeatures: FeatureTable = {
  id: 'featureTableDistinctiveFeatures',
  results: [
    feature('distinctiveFeature.portal'),
    feature('distinctiveFeature.golems'),
    feature('distinctiveFeature.black-prism'),
    feature('distinctiveFeature.prisoners'),
    feature('distinctiveFeature.blind-hermit'),
    feature('distinctiveFeature.bony-remains'),
    feature('distinctiveFeature.object', () => ({
      object: sample([
        'obelisk',
        'orb',
        'pyramid',
        'tesseract',
        'magneticStatue',
      ])!,
    })),
    feature('distinctiveFeature.moving-rooms'),
    feature('distinctiveFeature.high-ceilings'),
    feature('distinctiveFeature.artwork'),
    feature('distinctiveFeature.plants'),
    feature('distinctiveFeature.boiling-tar'),
    feature('distinctiveFeature.noisy-doors'),
    feature('distinctiveFeature.maniacs'),
    feature('distinctiveFeature.living-items'),
    feature('distinctiveFeature.flesh-and-bone'),
    feature('distinctiveFeature.eyes'),
    feature('distinctiveFeature.bulbous-flowers'),
    feature('distinctiveFeature.acid-ceilings'),
    feature('distinctiveFeature.salt-wind'),
  ],
};

export default {
  reason: Reasons,
  status: Status,
  entrance: Entrances,
  guard: Guards,
  danger: Dangers,
  inhabitants: Inhabitants,
  distinctiveFeature: DistinctiveFeatures,
};
