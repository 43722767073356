import { Room, DungeonState } from 'types/dungeon';
import { updateRoomDescriptions } from 'roll/Room';
import { rollDungeonName } from 'roll/DungeonName';
import { rollFeature } from 'roll/Feature';
import roomTables from 'roll/Room/tables';
import dungeonNameTables from 'roll/DungeonName/tables';
import featureTables from 'roll/Feature/tables';

export const updateDungeon = (state: DungeonState): DungeonState => {
  const roomsDeclarations = [
    {
      id: 'ROOM_1',
      tables: [roomTables.a, roomTables.b],
    },
    {
      id: 'ROOM_2',
      tables: [roomTables.a, roomTables.c],
    },
    {
      id: 'ROOM_3',
      tables: [roomTables.b, roomTables.d],
    },
    {
      id: 'ROOM_4',
      tables: [roomTables.ab, roomTables.cd],
    },
  ];
  return {
    ...state,
    name: rollDungeonName(dungeonNameTables),
    rooms: roomsDeclarations.reduce((rooms: { [id: string]: Room }, room) => {
      return {
        ...rooms,
        [room.id]: {
          id: room.id,
          tables: room.tables.map((table) => table.id),
          descriptions: updateRoomDescriptions(
            room.id,
            Object.values(rooms),
            room.tables
          ),
        },
      };
    }, {}),
    status: rollFeature(featureTables.status, state.status.id),
    distinctiveFeature: rollFeature(
      featureTables.distinctiveFeature,
      state.distinctiveFeature.id
    ),
    danger: rollFeature(featureTables.danger, state.danger.id),
    guard: rollFeature(featureTables.guard, state.guard.id),
    reason: rollFeature(featureTables.reason, state.reason.id),
    inhabitants: rollFeature(featureTables.inhabitants, state.inhabitants.id),
    entrance: rollFeature(featureTables.entrance, state.entrance.id),
  };
};
