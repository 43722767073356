import {
  DungeonNameTable,
  DungeonNameTableResult,
} from '../../../types/dungeon';
import random from 'lodash/fp/random';
import sample from 'lodash/fp/sample';

const result = (id: string): DungeonNameTableResult => {
  const fullId = `dungeon.name.${id}`;
  return {
    id: fullId,
  };
};

const resultWithValues = (
  id: string,
  generateValues: () => { [value: string]: number | string }
): DungeonNameTableResult => {
  const fullId = `dungeon.name.${id}`;
  return {
    id: fullId,
    generateValues,
  };
};

const resultWithMultipleOptions = (
  id: string,
  options: string[]
): DungeonNameTableResult => {
  const fullId = `dungeon.name.${sample(options) || id}`;
  return {
    id: fullId,
  };
};

export const TableA: DungeonNameTable = {
  id: 'A-the-something',
  results: [
    result('slaughter'),
    result('death'),
    result('night'),
    result('hadean'),
    result('hell'),
    result('plague'),
    result('sin'),
    result('doom'),
    result('dark'),
    result('murder'),
    result('torture'),
    result('ruined'),
    result('hateful'),
    result('wretched'),
    result('dying'),
    result('dismal'),
    result('rancid'),
    result('vile'),
    result('putrid'),
    result('tainted'),
    result('blood'),
    result('godless'),
    result('accursed'),
    result('charnel'),
    result('undying'),
    result('festering'),
    result('hungering'),
    result('shattered'),
    result('unholy'),
    result('profane'),
    result('unhallowed'),
    result('flayed'),
    result('dusk'),
  ],
};

export const TableB: DungeonNameTable = {
  id: 'B-someones',
  results: [
    result('devils'),
    result('deaths'),
    result('sinners'),
    result('heathens'),
    result('witchs'),
    result('fools'),
    result('hangmans'),
    result('widows'),
    result('dead-mans'),
    result('butchers'),
    result('wailers'),
    result('killers'),
    result('orphans'),
    result('hunters'),
    result('skinners'),
    result('hags'),
    result('lepers'),
    result('beggars'),
    result('hermits'),
  ],
};

export const TableD: DungeonNameTable = {
  id: 'D-noun',
  results: [
    result('pit'),
    result('church'),
    result('temple'),
    result('tunnels'),
    result('crypt'),
    result('grave'),
    result('fort'),
    result('ziggurat'),
    result('den'),
    result('maze'),
    result('house'),
    result('catacomb'),
    result('chasm'),
    result('tomb'),
    result('mausoleum'),
    result('vault'),
    result('ditch'),
    result('cave'),
    result('shrine'),
    result('chapel'),
    result('keep'),
    result('spire'),
    result('canals'),
    result('bridge'),
    result('docks'),
    result('funnel'),
  ],
};

export const TableE: DungeonNameTable = {
  id: 'E-of-something',
  results: [
    result('of-torment'),
    result('of-doom'),
    result('of-nechrubel'),
    resultWithValues('of-miseries', () => ({
      miseries: random(1, 6),
    })),
    result('of-suffering'),
    resultWithMultipleOptions('of-undeath', [
      'of-unending-undeath',
      'of-irreversible-undeath',
      'of-everlasting-undeath',
      'of-blasphemous-undeath',
    ]),
    result('of-madness'),
    result('of-unfaith'),
    result('of-flesh'),
    result('of-the-dying-earth'),
    result('of-misery'),
    result('of-malice'),
    resultWithValues('of-liches', () => ({
      liches: random(3, 7),
    })),
    result('of-surrender'),
    result('of-antipathy'),
    result('of-horrors'),
    result('of-repulsion'),
    result('of-cruelty'),
    resultWithMultipleOptions('of-dawn', ['of-false-dawn', 'of-lost-dawn']),
    resultWithMultipleOptions('of-gods', ['of-false-gods', 'of-dying-gods']),
    result('of-apathy'),
    result('of-spite'),
    result('of-repugnance'),
    result('of-annihilation'),
    result('of-darkness'),
    result('of-pain'),
    result('of-grief'),
    result('of-decay'),
    result('of-chaos'),
    result('of-evil'),
    result('of-rot'),
    result('of-ruin'),
    result('of-terror'),
    result('of-hatred'),
    result('of-lost-hopes'),
  ],
};

export const TableF: DungeonNameTable = {
  id: 'F-of-someone',
  results: [
    result('nagla'),
    result('nech-sarkon'),
    result('haat'),
    result('wrakmist'),
    resultWithMultipleOptions('x-nech', [
      'khaad-nech',
      'sumh-nech',
      'ghrud-nech',
      'phal-nech',
      'dhol-nech',
      'idh-nech',
    ]),
    result('rista'),
    result('kraeken-lat'),
    result('trots'),
    result('klor-prugel'),
    result('sechtar'),
    result('voluth'),
    result('domkratr'),
    result('knogbruth'),
    result('nagel-svinn'),
    result('wokath'),
    result('lermudh'),
    result('utvar'),
    result('schleg'),
    result('kagel'),
    result('nagel-mor'),
    result('crotan'),
    result('margdal'),
    result('grugl'),
  ],
};

export const TableG: DungeonNameTable = {
  id: 'G-someone-the-something',
  results: [
    result('mad'),
    result('unwilling'),
    resultWithMultipleOptions('x-touched', [
      'sun-touched',
      'death-touched',
      'chaos-touched',
    ]),
    result('voidborn'),
    result('killer'),
    result('anti-lich'),
    result('thrice-killed'),
    result('tormentor'),
    result('shunned'),
    result('exiled'),
    result('unborn'),
    result('two-headed'),
    result('pitiful'),
    result('horseman'),
    result('slayer'),
    result('child-thief'),
    result('cruel'),
    result('merciful'),
    result('unwanted'),
  ],
};

export default {
  something: TableA,
  someones: TableB,
  noun: TableD,
  ofSomething: TableE,
  someone: TableF,
  title: TableG,
};
