import { mediaQuery } from 'utils/mediaQueries';
import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledButton = styled.button`
  text-align: center;
  font-size: 1rem;
  font-family: 'Calling-code';
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: 0.5rem;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  width: auto;
  padding: 0;
  align-self: center;

  > span {
    background-color: ${({ theme }) => theme.highlight.primary};
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (hover: hover) and (pointer: fine) {
      :hover {
        background-color: ${({ theme }) => theme.highlight.inverted};
        color: ${({ theme }) => theme.text.inverted};
      }
    }
    :active {
      background-color: ${({ theme }) => theme.highlight.inverted};
      color: ${({ theme }) => theme.text.inverted};
    }
  }

  ${mediaQuery.phone} {
    font-size: 1rem;
  }

  ${mediaQuery.phone} {
    font-size: 1rem;
  }
`;
