import React from 'react';
import { StyledAngledBlock, FlexWrapper } from './styled';
import { DNGNGEN } from '../components/DNGNGEN';
import { OckultKartell } from '../components/OckultKartell';
import { MÖRKBORG } from '../components/MÖRKBORG';

const Header: React.FC = () => {
  return (
    <FlexWrapper>
      <StyledAngledBlock />
      <MÖRKBORG />
      <DNGNGEN />
      <OckultKartell />
    </FlexWrapper>
  );
};

export default Header;
