import get from 'lodash/fp/get';
import { Room, DungeonName, Feature, DungeonState } from 'types/dungeon';

export const selectRooms = (state: any): Room[] =>
  Object.values(get(['dungeon', 'rooms'], state));

export const selectDungeonName = (state: any): DungeonName =>
  get(['dungeon', 'name'], state);

export const selectFeature = (id: string) => (state: any): Feature =>
  get(['dungeon', id], state);

export const selectDungeon = (state: any): DungeonState =>
  get(['dungeon'], state);
