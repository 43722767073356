import React from 'react';
import { FlexWrapper } from './styled';
import Copyright from './components/Copyright';
import Version from './components/Version';
import Link from './components/Link';

const PrintFooter: React.FC = () => {
  return (
    <FlexWrapper>
      <Copyright />
      <Version />
      <Link />
    </FlexWrapper>
  );
};

export default PrintFooter;
