import app from './app/messages.json';
import rooms from './dungeon/rooms.json';
import names from './dungeon/names.json';
import reasons from './dungeon/reasons.json';
import status from './dungeon/status.json';
import entrances from './dungeon/entrances.json';
import guards from './dungeon/guards.json';
import distinctiveFeatures from './dungeon/distinctiveFeatures.json';
import inhabitants from './dungeon/inhabitants.json';
import dangers from './dungeon/dangers.json';

export default {
  ...app,
  ...rooms,
  ...names,
  ...reasons,
  ...status,
  ...entrances,
  ...guards,
  ...distinctiveFeatures,
  ...inhabitants,
  ...dangers,
};
