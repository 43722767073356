import styled from 'styled-components';
import { mediaQuery } from 'utils/mediaQueries';

type FlexWrapperProps = {
  row: number;
};

export const FlexWrapper = styled.div<FlexWrapperProps>`
  grid-column: 2;
  grid-row: ${({ row }) => row};
  max-height: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  border-bottom: 1px ${({ theme }) => theme.text.primary} solid;

  > div:not(:nth-last-child(1)) {
    border-right: 1px ${({ theme }) => theme.text.primary} solid;
  }

  ${mediaQuery.tablet} {
    grid-column: 1;
  }

  ${mediaQuery.phone} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    > div:nth-child(-n + 2) {
      border-left: none;
      border-right: none;
      border-bottom: 1px ${({ theme }) => theme.text.primary} solid;
    }
    > div:nth-child(odd) {
      border-left: none;
      border-right: 1px ${({ theme }) => theme.text.primary} solid;
    }
  }
`;
