import { mediaQuery } from 'utils/mediaQueries';
import styled from 'styled-components';

export const FlexWrapper = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  align-self: center;

  ${mediaQuery.tablet} {
    grid-column: 2;
    grid-row: 1;
    align-self: end;
  }

  ${mediaQuery.phone} {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
  }
`;

export const MBLogo = styled.img`
  z-index: 3;
  width: 220px;

  ${mediaQuery.phone} {
    width: 180px;
  }
`;

export const StyledText = styled.div`
  z-index: 3;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;

  ${mediaQuery.phone} {
    font-size: 0.9rem;
  }
`;
