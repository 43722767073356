import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FlexWrapper, TextWrapper } from './styled';
import { DungeonName } from 'types/dungeon';

type Props = {
  name: DungeonName;
  updateFn: () => { type: string };
};

const Name: React.FC<Props> = ({ name, updateFn }) => {
  const { formatMessage } = useIntl();
  const formattedMessages = Object.entries(name.messages).reduce(
    (result, [key, message]) => {
      return { ...result, [key]: formatMessage({ id: message }, name.values) };
    },
    {}
  );
  const formattedName = formatMessage({ id: name.format }, formattedMessages);

  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    setAnimating(!animating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const title = (
    <>{`${formatMessage({ id: 'app.name' })} - ${formattedName}`}</>
  );

  return (
    <FlexWrapper onClick={updateFn}>
      <title>{title}</title>
      <TextWrapper>{formattedName}</TextWrapper>
    </FlexWrapper>
  );
};

export default Name;
