import set from 'lodash/fp/set';
import get from 'lodash/fp/get';
import { Action } from 'types/ducks';
import { DungeonState } from 'types/dungeon';
import { updateRoomDescriptions } from 'roll/Room';
import { updateDungeon } from 'roll/Dungeon';
import { rollDungeonName } from 'roll/DungeonName';
import { rollFeature } from 'roll/Feature';
import roomTables from 'roll/Room/tables';
import dungeonNameTables from 'roll/DungeonName/tables';
import featureTables from 'roll/Feature/tables';

const getInitialState = () => ({
  rooms: {},
  distinctiveFeature: {
    id: 'distinctiveFeature.portal',
    message: 'dungeon.feature.distinctiveFeature.portal',
  },
  reason: {
    id: 'reason.dungeon-death',
    message: 'dungeon.feature.reason.dungeon-death',
  },
  danger: {
    id: 'danger.collapsing',
    message: 'dungeon.feature.danger.collapsing',
  },
  status: {
    id: 'status.still-active',
    message: 'dungeon.feature.status.still-active',
  },
  inhabitants: {
    id: 'inhabitants.escaped-experiment',
    message: 'dungeon.feature.inhabitants.escaped-experiment',
  },
  entrance: {
    id: 'entrance.trapdoor',
    message: 'dungeon.feature.entrance.trapdoor',
    values: { city: 'galgenbeck' },
  },
  guard: {
    id: 'guard.no-one',
    message: 'dungeon.feature.guard.no-one',
  },
  name: {
    format: 'dungeon.name.format.something-noun',
    messages: { something: 'dungeon.name.death', noun: 'dungeon.name.temple' },
    values: {},
  },
});

export const reducer = (
  state: DungeonState = getInitialState(),
  action: Action
) => {
  switch (action.type) {
    case 'UPDATE_DUNGEON':
      return updateDungeon(state);
    case 'UPDATE_DUNGEON_FEATURE':
      const featureId = action.payload!.id;
      const feature = rollFeature(
        get([featureId], featureTables),
        get([featureId], state).id
      );
      return set([featureId], feature, state);
    case 'UPDATE_DUNGEON_NAME':
      const name = rollDungeonName(dungeonNameTables);
      return set(['name'], name, state);
    case 'UPDATE_ROOM_DESCRIPTIONS':
      const roomId = action.payload!.id;
      const room = get(['rooms', roomId], state);
      const rooms = Object.values(get('rooms', state));
      const tables = Object.values(roomTables).filter((table) =>
        room.tables.includes(table.id)
      );
      return set(
        ['rooms', roomId],
        {
          ...room,
          descriptions: updateRoomDescriptions(roomId, rooms, tables),
        },
        state
      );
    default:
      return state;
  }
};
