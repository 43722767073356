import { FeatureTable, FeatureTableResult } from 'types/dungeon';
import random from 'lodash/fp/random';
import sample from 'lodash/fp/sample';

const description = (id: string): FeatureTableResult => {
  const message = `room.details.${id}`;
  return {
    id,
    message,
  };
};

const descriptionWithValues = (
  id: string,
  generateValues?: () => { [value: string]: number | string }
): FeatureTableResult => {
  const message = `room.details.${id}`;
  return {
    id,
    message,
    generateValues,
  };
};

export const TableA: FeatureTable = {
  id: 'roomTableA',
  results: [
    description('childish-inscriptions'),
    description('exquisite-chandeliers'),
    description('bloodied-beds'),
    description('flooded'),
    description('leaning'),
    description('upside-down-candles'),
    description('fire-damage'),
    description('black-ink'),
    descriptionWithValues('full-of-something', () => ({
      something: sample(['blood', 'bones', 'corpses'])!,
    })),
    description('torture-chamber'),
    description('sooty-walls'),
    description('freezing-draft'),
    description('creaking-doors'),
    description('compact-darkness'),
    description('locked-sarcophagi'),
    description('obscure-literature'),
    description('abyssal-pits'),
    description('whispering'),
    description('splattered-paint'),
    description('full-of-debris'),
    description('cracked-sacrificial-altar'),
    description('throne-remains'),
    description('smoke-filled'),
    description('bonfire'),
    description('piles-of-feathers'),
    description('hypnotic-inscriptions'),
    description('terrifying-inscriptions'),
    description('teleporting-inscriptions'),
    description('rotting-food'),
    description('bloody-sacrificial-altar'),
    description('small-aquarium'),
    description('cracked-sarcophagi'),
    descriptionWithValues('stinks-of-smell', () => ({
      smell: sample(['pepper', 'death', 'sweat'])!,
    })),
    description('crawling-with-bugs'),
    description('weapons-and-parts'),
    description('tally-marks'),
    description('charnel-house'),
    description('decapitated-statue'),
    description('box-of-ants'),
  ],
};

export const TableB: FeatureTable = {
  id: 'roomTableB',
  results: [
    description('incomprehensible-scroll'),
    description('smashed-game-board'),
    description('shattered-glass'),
    description('full-of-corpses'),
    description('animal-cadaver'),
    descriptionWithValues('container-filled-with-contents', () => ({
      container: sample(['barrel', 'ironPot', 'urn'])!,
      contents: sample(['stickyLiquid', 'blood', 'toes'])!,
    })),
    descriptionWithValues('weapon-stuck-in-door', () => ({
      weapon: sample(['spear', 'arrow', 'sword', 'goedendag'])!,
    })),
    descriptionWithValues('talking-skulls', () => ({
      amount: sample(['two', 'three'])!,
      creature: sample([
        'cat',
        'goat',
        'horse',
        'cow',
        'dog',
        'blank',
        'crow',
      ])!,
    })),
    description('bone-chair'),
    description('ruined-strawmen'),
    descriptionWithValues('caged-creatures', () => ({
      creatures: sample(['birds', 'snakes', 'swine'])!,
    })),
    description('mirrors-everywhere'),
    descriptionWithValues('busts', () => ({
      amount: sample(['two', 'three', 'four', 'five'])!,
      action: sample(['smiling', 'frowning', 'defaced'])!,
    })),
    description('nets-from-ceiling'),
    descriptionWithValues('words-on-wall', () => ({
      words: sample(['nech', 'watches', 'dontOpen', 'toys', 'sorry'])!,
    })),
    description('heavy-chest'),
    description('weird-shadows'),
    descriptionWithValues('sudden-sound', () => ({
      sound: sample(['shriek', 'cry', 'wail'])!,
    })),
    descriptionWithValues('basket', () => ({
      something: sample(['fruit', 'guts', 'bugs', 'heads'])!,
    })),
    description('dog-sized-beetle'),
    descriptionWithValues('chalk', () => ({
      chewed: sample(['chewed', 'notChewed'])!,
    })),
    description('covered-in-cobwebs'),
    description('echoing-words'),
    description('odd-rock-formation'),
    description('hard-to-breathe'),
    description('humming-curse'),
    descriptionWithValues('sawed-off-limb', () => ({
      limb: sample(['arm', 'leg', 'hand', 'foot'])!,
    })),
    descriptionWithValues('black-nails', () => ({
      variety: sample(['nails', 'fingernails'])!,
    })),
    descriptionWithValues('wooden-puzzle', () => ({
      pieces: random(1, 5) + 1,
    })),
    description('chewed-hempen-rope'),
    description('rotblack-sludge'),
    descriptionWithValues('broken-instrument', () => ({
      instrument: sample(['violin', 'flute', 'drum', 'harp', 'horn'])!,
    })),
    description('unlocked-chains'),
    description('hanging-statue'),
    descriptionWithValues('dead-prisoners', () => ({
      amount: random(1, 10) + random(1, 10),
    })),
    description('moldy-troughs'),
    descriptionWithValues('a-chair', () => ({
      noose: sample(['noose', 'noNoose'])!,
    })),
    description('gargoyles'),
    description('dead-dog'),
  ],
};

export const TableC: FeatureTable = {
  id: 'roomTableC',
  results: [
    description('altarbread'),
    description('drinking-horn'),
    description('burning-oil'),
    description('wall-holes'),
    description('well-dressed-corpse'),
    description('bells-and-marbles'),
    description('scorpion-filled-basket'),
    description('fish-hooks'),
    description('explosive-chest'),
    description('poison-gas-lock'),
    description('jewel-collapse'),
    description('slanted-floor'),
    description('snake-cages'),
    description('evil-urns'),
    description('poison-coins'),
    description('pit-trap'),
    description('spike-pit'),
    descriptionWithValues('sound-from-room', () => ({
      sound: sample([
        'singing',
        'screaming',
        'laughing',
        'coughing',
        'murmuring',
      ])!,
    })),
    descriptionWithValues('d4-life-elixirs', () => ({
      elixirs: random(1, 4),
    })),
    descriptionWithValues('d6-life-elixirs', () => ({
      elixirs: random(1, 6),
    })),
    descriptionWithValues('d100-silver-pieces', () => ({
      silver: random(1, 100),
    })),
    descriptionWithValues('d4+1-goblins', () => ({
      amount: random(1, 4) + 1,
      something: sample(['zweihander', 'arbalest', 'bomb', 'cat'])!,
    })),
    descriptionWithValues('d4-skeletons', () => ({
      skeletons: random(1, 4),
    })),
    description('plant-of-life'),
    description('plant-of-death'),
    descriptionWithValues('instrument', () => ({
      instrument: sample(['violin', 'flute', 'drum', 'harp', 'horn'])!,
    })),
    description('random-sacred-scroll'),
    description('random-unclean-scroll'),
    description('child-troll'),
    description('disturbing-paintings'),
    description('destroyed-armory'),
    description('caltrops'),
    descriptionWithValues('flayed-corpse', () => ({
      accessory: sample(['crown', 'necklace', 'mask'])!,
    })),
    descriptionWithValues('obsidian-basilisk', () => ({
      cracked: sample(['cracked', 'notCracked'])!,
    })),
  ],
};

export const TableD: FeatureTable = {
  id: 'roomTableD',
  results: [
    description('black-chrypt-fungi'),
    descriptionWithValues('cylinder-with-liquid', () => ({
      variety: sample(['life', 'death'])!,
    })),
    description('monkey-feces'),
    description('rotten-planks'),
    description('sticky-net'),
    descriptionWithValues('d4-zombies-with-chest', () => ({
      zombies: random(1, 4),
      silver: random(4, 400),
    })),
    description('plant-of-strength'),
    description('plant-of-senses'),
    description('venomous-spider'),
    description('unknown-one'),
    description('acidic-mud'),
    description('lich-with-spear'),
    description('guard-room'),
    description('wyvern-nest'),
    descriptionWithValues('dying-person', () => ({
      gender: sample(['male', 'female'])!,
      item: sample(['crown', 'shield', 'staff', 'statue'])!,
    })),
    descriptionWithValues('outcast', () => ({
      outcast: sample(['earthbound', 'wickhead', 'paleOne', 'prowler'])!,
      attitude: sample(['hostile', 'friendly', 'desperate'])!,
      food: sample(['food', 'noFood'])!,
    })),
    description('hideous-mirror'),
    description('strange-powers'),
    description('plague-ooze'),
    description('poisonous-mold'),
    description('powerful-staff'),
    descriptionWithValues('lost-outcast', () => ({
      outcast: sample(['earthbound', 'wickhead', 'paleOne', 'prowler'])!,
    })),
    description('invaluable-relic'),
    description('flayed-skin'),
    description('damaged-door'),
    description('sarcopha-ghost'),
    description('zombie-horde'),
    descriptionWithValues('alchemical-lab', () => ({
      doses: random(1, 4),
    })),
    description('haggard-mule'),
    description('doors-slammed-shut'),
    description('drunken-scum'),
    description('snake-basket'),
    description('inverted-gravity'),
    description('murderous-golem'),
  ],
};

export const TableAB: FeatureTable = {
  id: 'roomTableAB',
  results: [...TableA.results, ...TableB.results],
};

export const TableCD: FeatureTable = {
  id: 'roomTableCD',
  results: [...TableC.results, ...TableD.results],
};

export default {
  a: TableA,
  b: TableB,
  ab: TableAB,
  c: TableC,
  d: TableD,
  cd: TableCD,
};
