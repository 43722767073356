import React from 'react';
import { useSelector } from 'react-redux';
import { FlexWrapper, Menu } from './styled';
import Copyright from '../components/Copyright';
import Version from '../components/Version';
import PrintButton from '../components/PrintButton';
import CopyButton from '../components/CopyButton';
import { selectDungeon } from 'ducks/dungeon/selectors';

const Footer: React.FC = () => {
  const dungeon = useSelector(selectDungeon);

  return (
    <FlexWrapper>
      <Menu>
        <CopyButton dungeon={dungeon} />
        <PrintButton />
      </Menu>
      <Copyright />
      <Version />
    </FlexWrapper>
  );
};

export default Footer;
