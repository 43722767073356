import React from 'react';
import { FlexWrapper } from './styled';
import Header from '../../Header/containers';
import Dungeon from '../../Dungeon/containers';
import Footer from '../components/PrintFooter';

const Print: React.FC = () => {
  return (
    <FlexWrapper>
      <Header />
      <Dungeon />
      <Footer />
    </FlexWrapper>
  );
};

export default Print;
