import sample from 'lodash/fp/sample';
import { Room, FeatureTable, Feature } from 'types/dungeon';

const getTablesWithoutExistingRoomDescriptions = (
  tables: FeatureTable[],
  restrictedResults: string[]
) => {
  return tables.map((table) => {
    return {
      ...table,
      results: table.results.filter(
        (result) => !restrictedResults.includes(result.id)
      ),
    };
  });
};

const getExistingResults = (rooms: Room[]) => {
  return rooms.reduce((descriptions: string[], room) => {
    return [
      ...descriptions,
      ...room.descriptions.map((description) => description.id),
    ];
  }, []);
};

export const updateRoomDescriptions = (
  id: string,
  rooms: Room[],
  tables: FeatureTable[]
): Feature[] => {
  const otherRooms = rooms.filter((room) => room.id !== id);

  const restrictedResults = getExistingResults(otherRooms);

  const tablesWithoutAlreadyPresentResults = getTablesWithoutExistingRoomDescriptions(
    tables,
    restrictedResults
  );

  const descriptions = tablesWithoutAlreadyPresentResults.reduce(
    (results: Feature[], table) => {
      const result = sample(table.results);
      if (!result) {
        return results;
      }
      const values = result.generateValues ? result.generateValues() : {};
      return [
        ...results,
        {
          ...result,
          values,
        },
      ];
    },
    []
  );
  return descriptions;
};

export const rollRoom = (
  id: string,
  rooms: Room[],
  tables: FeatureTable[]
): Room => {
  return {
    id,
    descriptions: updateRoomDescriptions(id, rooms, tables),
    tables: tables.map((table) => table.id),
  };
};
