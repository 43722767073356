import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 100%) 800px minmax(0px, 100%);
  grid-template-rows:
    120px 60px minmax(min-content, max-content)
    1fr;
  height: 100%;
  width: 100%;
`;
