import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Feature } from 'types/dungeon';
import { FlexWrapper, ResultText, ResultTitle } from './styled';
import { BuzzOut } from 'utils/animations';

type Props = {
  title: string;
  feature: Feature;
  updateFn: () => { type: string; payload: { id: string } };
};

const FeatureBox: React.FC<Props> = ({ title, feature, updateFn }) => {
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    setAnimating(!animating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  return (
    <FlexWrapper>
      <BuzzOut key={`${animating}`}>
        <ResultTitle onClick={updateFn}>
          <span>
            <FormattedMessage id={`dungeon.${title}.title`} />
          </span>
        </ResultTitle>
      </BuzzOut>
      <ResultText>
        <FormattedHTMLMessage id={feature.message} values={feature.values} />
      </ResultText>
    </FlexWrapper>
  );
};

export default FeatureBox;
