import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FormattedMessage } from 'react-intl';
import { FlexWrapper, StyledButton } from './styled';
import Print from 'views/Print/containers';
import { BuzzOut } from 'utils/animations';

export const PrintButton: React.FC = () => {
  const ref = useRef(null);
  const [animating, setAnimating] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleClick = () => {
    setAnimating(!animating);
  };

  return (
    <FlexWrapper>
      <BuzzOut key={`${animating}`}>
        <StyledButton onClick={handlePrint}>
          <span onClick={handleClick}>
            <FormattedMessage id={'app.print'} />
          </span>
        </StyledButton>
      </BuzzOut>
      <div style={{ display: 'none' }}>
        <div ref={ref}>
          <Print />
        </div>
      </div>
    </FlexWrapper>
  );
};

export default PrintButton;
