import styled from 'styled-components';
import { mediaQuery } from 'utils/mediaQueries';

export const FlexWrapper = styled.div`
  grid-row: 1;
  grid-column: 2;
  text-align: center;
  padding: 2rem;

  ${mediaQuery.tablet} {
    grid-column: 1;
    grid-row: 1;
  }

  ${mediaQuery.phone} {
    padding: 1rem;
  }
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-family: 'Gothicus';
  font-size: 5rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  line-height: 5rem;
  opacity: 1;
  transition: opacity 1s;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.75;
    }
  }

  :active {
    opacity: 0.75;
  }

  ${mediaQuery.phone} {
    font-size: 3.25rem;
    line-height: 3.25rem;
  }
`;
