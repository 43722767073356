import styled from 'styled-components';
import openDoor from 'assets/door_opened.png';
import closedDoor from 'assets/door_closed.png';
import { buzzOut } from 'utils/animations';
import { mediaQuery } from 'utils/mediaQueries';

export const FlexWrapper = styled.div`
  z-index: 4;
  grid-column: 1 / span 3;
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 1em;
  border-bottom: 1px ${({ theme }) => theme.text.primary} solid;
  ${mediaQuery.phone} {
    padding-top: 0em;
    padding-bottom: 0.5em;
  }
`;

export const DoorButton = styled.button`
  background-image: url(${closedDoor});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 1em;
  margin-top: 1em;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-family: calling-code, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1em;
  padding: 0;
  text-transform: uppercase;
  height: 200px;
  transition: background-image 0.25s;
  user-select: none;

  ${mediaQuery.phone} {
    height: 160px;
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      transition: background-image 0.25s;
      background-image: url(${openDoor});
      > span {
        color: ${({ theme }) => theme.text.inverted};
        background-color: ${({ theme }) => theme.highlight.inverted};
      }
    }
  }
  > span {
    background-color: ${({ theme }) => theme.highlight.secondary};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  :active {
    transition: background-image 0s;
    background-image: url(${openDoor});
    -webkit-animation: ${buzzOut()} 0.2s;
    -moz-animation: ${buzzOut()} 0.2s;
    -o-animation: ${buzzOut()} 0.2s;
    animation: ${buzzOut()} 0.2s;
    > span {
      color: ${({ theme }) => theme.text.inverted};
      background-color: ${({ theme }) => theme.highlight.inverted};
    }
  }
`;
