import { mediaQuery } from 'utils/mediaQueries';
import styled from 'styled-components';

export const StyledAngledBlock = styled.div`
  z-index: 1;
  grid-column: 1 / span 5;
  grid-row: 1;
  height: 90%;
  background-color: ${({ theme }) => theme.faded};
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  height: 90%;

  ${mediaQuery.tablet} {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
    height: 90%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  }

  ${mediaQuery.phone} {
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 70%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

export const FlexWrapper = styled.div`
  grid-row: 1;
  grid-column: 1 / span 3;
  display: grid;
  grid-template-columns: minmax(0px, 1fr) 260px 320px 260px minmax(0px, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 40px;

  ${mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100px 1fr;
  }

  ${mediaQuery.phone} {
    grid-column: 1 / span 3;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 140px;
  }
`;
