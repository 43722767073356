import React from 'react';
import logo from 'assets/MB-logo.svg';
import { FormattedMessage } from 'react-intl';
import { FlexWrapper, MBLogo, StyledText } from './styled';

export const MÖRKBORG: React.FC = () => {
  return (
    <FlexWrapper>
      <StyledText>
        <FormattedMessage id='app.introduction' />
      </StyledText>
      <MBLogo src={logo} alt='the MÖRK BORG logo' />
    </FlexWrapper>
  );
};
