import { mediaQuery } from 'utils/mediaQueries';
import styled, { keyframes } from 'styled-components';

export const drip = keyframes`
  0% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(1.5);
  }`;

export const StyledAngledBlock = styled.div`
  z-index: 2;
  grid-column: 1;
  grid-row: 1;
  background: ${({ theme }) => theme.highlight.primary};
  -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
  height: 100px;
  width: 320px;
  margin-bottom: -10px;
`;

export const FlexWrapper = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  align-items: center;

  ${mediaQuery.tablet} {
    grid-column: 1 / span 3;
    grid-row: 2;
  }

  ${mediaQuery.phone} {
    grid-column: 1;
    grid-row: 2;
  }
`;

export const Tendrils = styled.div`
  will-change: transform;
  z-index: 2;
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 0;
  margin-top: 100px;
  margin-bottom: -8rem;
  height: 172px;
  width: 320px;
  justify-items: center;

  div:nth-child(1) {
    grid-column: 2;
    grid-row: 1;
    transform-origin: top center;
    height: 80px;
    animation: ${drip} 5.5s infinite alternate;
  }

  div:nth-child(2) {
    grid-column: 3;
    grid-row: 1;
    height: 65px;
    transform-origin: top center;
    animation: ${drip} 4s infinite alternate;
  }

  div:nth-child(3) {
    grid-column: 18;
    grid-row: 1;
    height: 55px;
    transform-origin: top center;
    animation: ${drip} 5s infinite alternate;
  }

  div:nth-child(4) {
    grid-column: 23;
    grid-row: 1;
    height: 120px;
    transform-origin: top center;
    animation: ${drip} 7s infinite alternate;
  }
`;

export const Tendril = styled.div`
  background: ${({ theme }) => theme.highlight.primary};
  border-radius: 10px;
  width: 6px;
`;

export const DNGNGENLogo = styled.img`
  z-index: 3;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: -10px;
  height: 80%;
  width: 320px;
  transition: transform(translatey) 2s;
`;
