import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FlexWrapper, DoorButton } from './styled';

const Door: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <FlexWrapper>
      <DoorButton onClick={() => dispatch({ type: 'UPDATE_DUNGEON' })}>
        <span>
          <FormattedHTMLMessage id='app.generate-new-dungeon' />
        </span>
      </DoorButton>
    </FlexWrapper>
  );
};

export default Door;
