import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'ducks/theme/selectors';
import { GlobalStyle } from 'styled/GlobalStyle';
import { FlexWrapper } from './styled';
import Header from 'views/Header/containers';
import Door from 'views/Door/containers';
import Dungeon from 'views/Dungeon/containers';
import Footer from 'views/Footer/containers';

const MainPage: React.FC = () => {
  const theme = useSelector(selectTheme);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <FlexWrapper>
        <Header />
        <Door />
        <Dungeon />
        <Footer />
      </FlexWrapper>
    </ThemeProvider>
  );
};

export default MainPage;
