import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import get from 'lodash/fp/get';
import { FlexWrapper, StyledButton } from './styled';
import { DungeonState, Room, Feature } from 'types/dungeon';
import { BuzzOut } from 'utils/animations';

type Props = {
  dungeon: DungeonState;
};

const removeHtmlFrom = (str: string) => {
  const regex = /(<.+>)(.*)(<\/.*>)/gi;
  return str.replace(regex, '$2');
};

export const CopyButton: React.FC<Props> = ({ dungeon }) => {
  const { formatMessage } = useIntl();
  const [animating, setAnimating] = useState(false);

  const formatFeature = (category: string) => {
    const feature: Feature = get(category, dungeon);
    return `${formatMessage({ id: `dungeon.${category}.title` })}
${formatMessage({ id: feature.message }, feature.values)}`;
  };

  const formatRooms = (r: Room[]) => {
    return r.map((room, index) => {
      const title = `${formatMessage(
        { id: 'room.title' },
        { roomNumber: index + 1 }
      )}`;
      const descriptions = room.descriptions
        .map((description) => {
          return formatMessage({ id: description.message }, description.values);
        })
        .join(' ');
      return `${title}
${removeHtmlFrom(descriptions)}
`;
    }).join(`
`);
  };

  const dungeonNameParts = Object.entries(dungeon.name.messages).reduce(
    (result, [key, message]) => {
      return {
        ...result,
        [key]: formatMessage({ id: message }, dungeon.name.values),
      };
    },
    {}
  );
  const dungeonName = formatMessage(
    { id: dungeon.name.format },
    dungeonNameParts
  );

  const status = formatFeature('status');

  const danger = formatFeature('danger');

  const inhabitants = formatFeature('inhabitants');

  const entrance = formatFeature('entrance');

  const guard = formatFeature('guard');

  const distinctiveFeature = formatFeature('distinctiveFeature');

  const reason = formatFeature('reason');

  const rooms = formatRooms(Object.values(dungeon.rooms));

  const introduction = `${formatMessage({ id: 'app.introduction' })} MÖRK BORG`;

  const copyright = `${formatMessage({ id: 'app.copyright' })}`;

  const copyText = `\`\`\`🕇 D N G N G E N 🕇
_________________
→ ${introduction} ←
${copyright}
_________________

${dungeonName.toUpperCase()}

${reason}

${status}

${danger}

${inhabitants}

${entrance}

${removeHtmlFrom(guard)}

${distinctiveFeature}

${rooms}\`\`\``;

  return (
    <FlexWrapper>
      <CopyToClipboard text={copyText}>
        <BuzzOut key={`${animating}`}>
          <StyledButton onClick={() => setAnimating(!animating)}>
            <span>{formatMessage({ id: 'app.copy' })}</span>
          </StyledButton>
        </BuzzOut>
      </CopyToClipboard>
    </FlexWrapper>
  );
};

export default CopyButton;
